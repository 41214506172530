import React, { HTMLAttributes, useState, useEffect, useRef } from 'react'
import styled, { css } from 'styled-components'
import { toCssPrefix, getTimeToDate, breakpoints, useLocation } from '@ecommerce/shared'
import { navigate } from 'gatsby'
import { Countdown as CountdownProps, CountdownType } from '../../graphql/contentfulTypes'

type StyleProps = {
  colorScheme?: 'dark' | 'light'
  backgroundMobile?: string
  backgroundTablet?: string
  backgroundDesktop?: string
  backgroundMobileAlignment?: string
  backgroundTabletAlignment?: string
  backgroundDesktopAlignment?: string
  slug?: string
}

const { cssPrefix, toPrefix } = toCssPrefix('Countdown__')

const baseDigitStyles = css`
  height: 32px;
  width: 24px;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 0;
  margin: 2.5px;
  @media screen and (${breakpoints.desktop.min}) {
    height: 32px;
    width: 28px;
  }
`

const DefaultWrapper = styled.div<StyleProps>`
  cursor: ${({ slug }) => (slug ? 'pointer' : 'default')};
  width: 100vw;
  min-width: 100vw;
  margin-bottom: 20px;
  background-image: ${({ backgroundMobile }) => (backgroundMobile ? `url("${backgroundMobile}")` : '')};
  background-repeat: no-repeat;
  background-size: cover;
  height: 140px;
  user-select: none;

  .${cssPrefix} {
    &container {
      color: ${({ theme: { colors }, colorScheme }) => (colorScheme === `light` ? colors.black : colors.white)};
      display: flex;
      justify-content: center;
      flex-direction: column;
      width: 100%;
      height: inherit;
      user-select: none;
      align-items: ${({ backgroundMobileAlignment }) => {
        if (backgroundMobileAlignment === 'left') return 'flex-start'
        if (backgroundMobileAlignment === 'right') return 'flex-end'
        return 'center'
      }};

      &-info {
        height: inherit;
        display: flex;
        flex-direction: column;
        width: fit-content;
        align-items: center;
        padding: 22px 0 13px 16px;
      }

      &-hour {
        display: flex;
      }

      &-digit {
        display: flex;
      }

      &-digit-hour {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        gap: 4px;
      }
    }

    &title,
    &subtitle,
    &label-time {
      color: ${({ theme: { colors }, colorScheme }) => (colorScheme === `light` ? colors.white : colors.black)};
      margin: 0;
    }

    &title {
      font-size: 18px;
      line-height: 22px;
      letter-spacing: -0.25px;
    }

    &subtitle {
      font-size: 16px;
      line-height: 22px;
      letter-spacing: 0.1px;
      margin-bottom: 12px;
    }

    &label-time {
      font-size: 11px;
      line-height: 13px;
      letter-spacing: 0.05px;
      font-weight: bold;
    }

    &digit {
      background-color: ${({ theme: { colors }, colorScheme }) =>
        colorScheme === `light` ? colors.white : colors.black};
      color: inherit;
      font-size: 20px;
      font-weight: bold;
      border-radius: ${({ theme }) => theme.borderRadius};

      ${baseDigitStyles}
      &-number {
        margin-top: 4px;
      }

      &-divider {
        font-size: 34px;
        color: ${({ theme: { colors }, colorScheme }) => (colorScheme === 'light' ? colors.white : colors.black)};
      }
    }
  }

  @media screen and (${breakpoints.tabletPortrait.min}) {
    background-image: ${({ backgroundTablet }) => (backgroundTablet ? `url("${backgroundTablet}")` : '')};
    .${cssPrefix} {
      &container {
        align-items: ${({ backgroundTabletAlignment }) => {
          if (backgroundTabletAlignment === 'left') return 'flex-start'
          if (backgroundTabletAlignment === 'right') return 'flex-end'
          return 'center'
        }};
        &-info {
          padding: 18px 0 18px 133px;
        }
      }
    }
  }

  @media screen and (${breakpoints.desktop.min}) {
    background-image: ${({ backgroundDesktop }) => (backgroundDesktop ? `url("${backgroundDesktop}")` : '')};
    .${cssPrefix} {
      &container {
        align-items: ${({ backgroundDesktopAlignment }) => {
          if (backgroundDesktopAlignment === 'left') return 'flex-start'
          if (backgroundDesktopAlignment === 'right') return 'flex-end'
          return 'center'
        }};
        &-info {
          padding: 14px 110px 12px 116px;
        }
      }

      &digit {
        font-size: 24px;
      }

      &title {
        font-size: 24px;
        line-height: 31px;
        letter-spacing: 0.11px;
      }

      &subtitle {
        font-size: 18px;
        line-height: 22px;
        letter-spacing: -0.25px;
        margin-bottom: 12px;
      }

      &label-time {
        font-size: 11px;
        line-height: 13px;
        letter-spacing: 0.05px;
        font-weight: bold;
      }
    }
  }
`

const digitsDivider = <span className={toPrefix('digit-divider')}>:</span>
const labelTime = (label: string) => <p className={toPrefix('label-time')}>{label}</p>

const toTwoDigitsString = (num: number) => (num > 0 ? `${num}`.padStart(2, '0') : '00')
const toCountdownDigits = (num: number, appendDivider?: boolean, label?: string) => {
  return (
    <>
      <div className={toPrefix('container-digit-hour')}>
        <div className={toPrefix('container-digit')}>
          {[
            ...toTwoDigitsString(num)
              .split('')
              .map((char) => (
                <div className={toPrefix('digit')}>
                  <span className={toPrefix('digit-number')}>{char}</span>
                </div>
              )),
          ]}
        </div>
        {label && labelTime(label)}
      </div>
      {appendDivider && digitsDivider}
    </>
  )
}

export const Countdown = ({
  targetDate,
  type,
  displayDays,
  colorScheme,
  className,
  backgroundDesktop,
  backgroundTablet,
  backgroundMobile,
  backgroundDesktopAlignment,
  backgroundTabletAlignment,
  backgroundMobileAlignment,
  title,
  subtitle,
  slug,
}: CountdownProps & HTMLAttributes<HTMLDivElement>) => {
  const ref = useRef<HTMLDivElement>(null)

  const {
    state: { currentCity },
  } = useLocation()

  const [state, setState] = useState({
    days: 0,
    hours: 0,
    minutes: 0,
    seconds: 0,
  })

  const [intervalId, setIntervalId] = useState(0)

  useEffect(() => {
    setState(getTimeToDate(new Date(targetDate)))
    setIntervalId(
      setInterval(function tick() {
        setState(getTimeToDate(new Date(targetDate)))
      }, 1000),
    )
    return function removeInterval() {
      clearInterval(intervalId)
    }
  }, [targetDate])

  const getWrapper = () => {
    switch (type) {
      case CountdownType.DEFAULT:
        return DefaultWrapper
      default:
        return DefaultWrapper
    }
  }
  const Wrapper = getWrapper()

  return (
    <Wrapper
      colorScheme={colorScheme}
      className={className}
      backgroundMobile={`${backgroundMobile?.url}?q=90`}
      backgroundTablet={`${backgroundTablet?.url}?q=90`}
      backgroundDesktop={`${backgroundDesktop?.url}?q=90`}
      backgroundMobileAlignment={backgroundMobileAlignment}
      backgroundTabletAlignment={backgroundTabletAlignment}
      backgroundDesktopAlignment={backgroundDesktopAlignment}
      slug={slug}
      ref={ref}
      onClick={(event) => {
        event.stopPropagation()
        event.preventDefault()
        if (slug) {
          return navigate(slug?.startsWith('/') ? `${slug}` : `/${slug}`)
        }
        return null
      }}
    >
      <div className={toPrefix('container')}>
        <div className={toPrefix('container-info')}>
          <h4 className={toPrefix('title')}>{title}</h4>
          <h5 className={toPrefix('subtitle')}>{subtitle}</h5>
          <div className={toPrefix('container-hour')}>
            {displayDays && state.days !== 0 && toCountdownDigits(state.days, true, 'DIAS')}
            {toCountdownDigits(state.hours ?? 0, true, 'HORAS')}
            {toCountdownDigits(state.minutes ?? 0, !displayDays || state.days === 0, 'MIN')}
            {(!displayDays || state.days === 0) && toCountdownDigits(state.seconds ?? 0, false, 'SEG')}
          </div>
        </div>
      </div>
    </Wrapper>
  )
}
