import { ClientSideBlockQueryResult, Countdown, BlockPadding, BlockTitle } from './contentfulTypes'
import { OrListFragment } from './fragments'

export type CountdownQueryResult = ClientSideBlockQueryResult<Countdown>
export const extractCountdownBlockData = (
  queryResult: CountdownQueryResult,
): { wrapperProps: BlockPadding & BlockTitle; countdownProps: Countdown } | undefined => {
  if (!queryResult.orList?.itemsCollection?.items?.length) return undefined
  return {
    wrapperProps: {
      ...queryResult?.orList,
    },
    countdownProps: { ...queryResult?.orList.itemsCollection.items[0] },
  }
}

export default `
  query CountdownQuery($listId: String!) {
    orList(id: $listId) {
      ...OrListFragment
      itemsCollection {
        items {
          ... on MlCountdown {
            targetDate
            type
            displayDays
            colorScheme
            title
            subtitle
            backgroundMobile {
              url
            }
            backgroundTablet {
              url
            }
            backgroundDesktop {
              url
            }
            backgroundMobileAlignment
            backgroundTabletAlignment
            backgroundDesktopAlignment
            slug
          }
        }
      }
    }
  }
  ${OrListFragment}
`
