import React, { useEffect, useState } from 'react'
import useQuery from '../../hooks/useQuery'
import countdownQuery, { extractCountdownBlockData, CountdownQueryResult } from '../../graphql/countdownQuery'
import { SimpleBlockWrapper } from '../../utils/blocks'
import { Countdown } from '.'
import SectionTitle from '../Moments/SectionTitle'

interface HeadingProps {
  listId: string
}

const CountdownWidget = ({ listId }: HeadingProps) => {
  const [data, setData] = useState<ReturnType<typeof extractCountdownBlockData>>(undefined)
  const { data: results, loading } = useQuery<CountdownQueryResult>(countdownQuery, { variables: { listId } })
  useEffect(() => {
    if (results && !loading) {
      const newData = extractCountdownBlockData(results)
      if (newData) setData(newData)
    }
  }, [results, loading])
  if (!data?.countdownProps?.targetDate || !data?.countdownProps.type) return null

  return (
    <SimpleBlockWrapper {...(data?.wrapperProps ?? {})}>
      {data?.wrapperProps.title && (
        <SectionTitle color={data.wrapperProps.titleColor} text={data.wrapperProps.title} {...data.wrapperProps} />
      )}
      <Countdown {...data.countdownProps} />
    </SimpleBlockWrapper>
  )
}

export default CountdownWidget
